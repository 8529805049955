import axios from 'axios'
import { nanoid } from 'nanoid'
import { captureException } from '~/common/helpers'
import { action, computed, observable, persist, storage, store, toJS } from '~/common/mobx.decorator'
import { THEMES } from '~/themes'
import { notifyStore } from '~/stores/notify.store'
import { createMuiTheme } from '@material-ui/core'
import { isEmpty } from 'lodash'

axios.defaults.baseURL = `${process.env.REACT_APP_PROXY_API_URL}/api/public/v1`
axios.defaults.headers['Tenant-Id'] = process.env.REACT_APP_COMPANY_ID

axios.interceptors.response.use(undefined, (error) => {
	captureException('Axios Client', error)
	return Promise.reject(error)
})

@store({ persist: true })
class AppStore {
	constructor() {
		storage.ready(() => {
			this.ready = true
			if (!this._uniqueId) {
				this._uniqueId = nanoid()
			}
		})
	}

	@persist @observable _uniqueId
	@observable config = null
	@observable ready = false

	@observable chatIntegrationSetting
	@observable meta_domain
	@observable store_android_url
	@observable store_ios_url
	@observable jobBoardName = null
	@observable jobBoardWebDomain = null
	@observable jobBoardDescription = null
	@observable jobBoardCode = 'ghr'
	@persist('object') @observable jobBoardTheme = null

	@computed
	get id() {
		// Company Id
		return process.env.REACT_APP_COMPANY_ID
	}

	@computed
	get name() {
		return process.env.REACT_APP_COMPANY_NAME
	}

	@computed
	get welcomeLogo() {
		return `/assets/${this.jobBoardCode?.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase() || 'ghr'}/welcome-logo.png`
	}

	@computed
	get getTheme() {
		if (this.jobBoardTheme) {
			return toJS(this.jobBoardTheme)
		}

		return THEMES.candidate
	}

	@computed
	get hideBanner() {
		return this.jobBoardCode === 'ghr'
	}

	@action
	init = async () => {
		await this.fetchThemeConfig()
		await this.fetchFEConfig()
		await this.fetchAppConfig()
	}

	fetchAppConfig = async () => {
		const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
		if (!isMobile) {
			await notifyStore.initOneSignal().then(() => notifyStore.sendInfo())
		}
	}

	fetchFEConfig = async () => {
		const response = await fetch('/app-config.json')
		const data = await response.json()

		return data
	}

	@action
	setJobBoardThemeConfig = (config) => {
		this.jobBoardThemeConfig = config
	}

	@action
	setJobBoardConfig = (config) => {
		this.jobBoardName = config?.name
		this.jobBoardWebDomain = config?.web_domain
		this.jobBoardDescription = config?.description
		if (config?.custom_code) {
			this.jobBoardCode = config?.custom_code
		}
	}

	@action
	fetchThemeConfig = async () => {
		console.log('window.location.origin', window.location.origin)
		if (this.jobBoardTheme || window.location.origin === process.env.REACT_APP_JOB_BOARD_WEB_DOMAIN) {
			return
		}
		const isDevelopment = window.location.hostname === 'localhost'
		try {
			const headerConfig = {
				headers: {
					Accept: 'application/json',
					...(isDevelopment && { JOB_BOARD_ID: process.env.REACT_APP_JOB_BOARD_ID || '' }),
				},
			}

			const { data } = await axios.get(`${process.env.REACT_APP_PROXY_API_URL}/api/public/v1/job_boards`, headerConfig)
			if (data?.theme_config && !isEmpty(data?.theme_config)) {
				const apiThemeConfig = typeof data?.theme_config === 'string' ? JSON.parse(data.theme_config) : data.theme_config

				const theme = createMuiTheme(apiThemeConfig)

				this.jobBoardTheme = theme
			}
			this.setJobBoardConfig(data)
		} catch (error) {
			console.error('Failed to fetch theme config:', error)
		}
	}
}

export const appStore = new AppStore()
